import React from "react";

const Footer = () => {
  return (
    <div className="footer">
      <div className="footerLink">
        <a className="linksFooter" href="/">
          Home
        </a>
        <a className="linksFooter" href="#/discover">
          Chi siamo
        </a>
        <a className="linksFooter" href="#/find-us">
          Contattaci
        </a>
      </div>
      <p className="paragraph">© 2023 - 2024 Tipico Siculo - All Rights Reserved.</p>
    </div>
  );
};
export default Footer;
