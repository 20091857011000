import Titolo from "../assets/img/Titolo.png"
import Logo2 from "../assets/img/Logo2.png"
import Logo3 from "../assets/img/Logo3.png"

const Title = () => {
    return (
        <>
            <div className="titles">
                <div className="titleRolling">
                    <img
                        src={Titolo}
                        alt="titolo"
                    />
                    <img
                        src={Logo2}
                        alt="titolo"
                    />
                    <img
                        src={Logo3}
                        alt="titolo"
                    />
                    <img
                        src={Titolo}
                        alt="titolo"
                    />
                    <img
                        src={Logo2}
                        alt="titolo"
                    />
                    <img
                        src={Logo3}
                        alt="titolo"
                    />
                </div>
                <div className="titleRolling">
                    <img
                        src={Titolo}
                        alt="titolo"
                    />
                    <img
                        src={Logo2}
                        alt="titolo"
                    />
                    <img
                        src={Logo3}
                        alt="titolo"
                    />
                    <img
                        src={Titolo}
                        alt="titolo"
                    />
                    <img
                        src={Logo2}
                        alt="titolo"
                    />
                    <img
                        src={Logo3}
                        alt="titolo"
                    />
                </div>
            </div>
        </>
    );
};
export default Title;